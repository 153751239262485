import {
  Title,
  Text,
  Container,
  Group,
  Button,
  Box,
  Center,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import classes from "./index.module.css";

const ErrorPage: React.FC<TErrorData> = ({
  errorCode = 404,
  errorTitle = "Invalid URL",
  errorMessage = "The requested url was not found",
}) => {
  const navigate = useNavigate();

  return (
    <Center className={classes.root} mih="100vh">
      <Container>
        <Box className={classes.label}>{errorCode}</Box>
        <Title className={classes.title}>{errorTitle}</Title>
        <Text size="lg" ta="center" className={classes.description}>
          {errorMessage}
        </Text>
        <Group justify="center">
          <Button className={classes.goBackBtn} onClick={() => navigate(-1)}>
            Go back
          </Button>
        </Group>
      </Container>
    </Center>
  );
};
export default ErrorPage;
