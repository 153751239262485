import React from "react";
import HeaderField from "../../container/header/Index";
import QRContainer from "./components/QRContainer";
import ErrorBoundary from "../../component/error-boundry/ErrorBoundry";
import { Flex } from "@mantine/core";

const Dashboard = () => {
  return (
    <ErrorBoundary>
      <Flex direction={"column"} h={"100vh"}>
        <HeaderField />
        <QRContainer />
      </Flex>
    </ErrorBoundary>
  );
};

export default Dashboard;
