import { showNotification } from "@mantine/notifications";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const loginPath = "/login";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  withCredentials: true,
});

export const request = async (options: AxiosRequestConfig<any>) => {
  const token = localStorage.getItem("token");
  client.defaults.headers.common.authorization = `Bearer ${token}`;
  const onSuccess = (response: AxiosResponse) => response.data;
  const onError = (error: any) => {
    if (error.response.data.statusCode >= 500) {
      showNotification({
        title: error.response?.data?.title,
        message: error.response?.data?.message,
        color: "red",
      });
      window.location.replace("/something-went-wrong");
    }

    if (
      error.response.data.statusCode === 401 &&
      window.location.pathname !== loginPath
    ) {
      window.location.replace(loginPath);
    }

    return error.response;
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
