import {
  ActionIcon,
  Box,
  Group,
  Container,
  Paper,
  Tooltip,
} from "@mantine/core";
import React from "react";
import IMAGES from "../../image";
import { IconLogout } from "@tabler/icons-react";
import classes from "./header.module.css";
import { useLogoutMutation } from "../../hooks/login/mutation/useLogout.mutation";
const HeaderField: React.FC = () => {
  const { mutateAsync } = useLogoutMutation();
  return (
    <Paper className={classes.mainPaper}>
      <Container className={classes.header} miw={"100%"}>
        <Box className={classes.flexContainer}>
          <Box>
            <Group>
              <img
                className={classes.logo}
                src={IMAGES.logoWhite}
                alt="img not found"
              />
            </Group>
          </Box>
          <Box className={classes.containerRight}>
            <Tooltip label="Logout">
              <ActionIcon
                variant="transparent"
                mt="0.5rem"
                onClick={() => mutateAsync()}
              >
                <IconLogout size="2rem" color="white" />
              </ActionIcon>
            </Tooltip>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default HeaderField;
