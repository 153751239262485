import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const logout = async () => {
  const res: TServerResponse = await request({
    url: apiUrls.LOGOUT,
    method: "POST",
  });
  return res;
};

export const useLogoutMutation = () => {
  return useMutation({ mutationFn: logout });
};
