import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
const loginPath = "/login";
const checked = async () => {
  const res: TServerResponse = await request({
    url: apiUrls.IS_LOGIN,
    method: "GET",
  });

  if (res.status === "success" && window.location.pathname === loginPath) {
    window.location.replace("/");
  }
  return res;
};

export const useCheckedIsLoginQuery = () => {
  return useQuery({ queryKey: ["qr", "is-login"], queryFn: checked });
};
