import { memo } from "react";
import Dashboard from "../pages/dashboard/Index";
import { useCheckedIsLoginQuery } from "../hooks/qr-code/query/useCheckedIsLogin.query";

const ProtectedRoute = () => {
  useCheckedIsLoginQuery();
  return <Dashboard />;
};

export default memo(ProtectedRoute);
